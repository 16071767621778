import handle_charts from './charts.js';
import { mapboxMap } from './meteoblue.js';

const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight()

const searchInput = document.getElementById('citySearch');
const searchResults = document.getElementById('searchResults');

// Function to handle location selection (used by both search and map click)
async function handleLocationSelect(lat, lon) {
    try {
        const response = await fetch(`/api/city?city=${lat},${lon}`);
        const data = await response.json();
        
        if (data.body && data.body.results && data.body.results.length > 0) {
            const city = data.body.results[0];
            
            // Adjust map center based on screen size
            const isMobile = window.innerWidth <= 768;
            const center = isMobile ? 
                [city.lon, city.lat] : 
                [city.lon + (document.querySelector('.layout').classList.contains('show-charts') ? 0.2 : 0), city.lat];

            mapboxMap.flyTo({
                center: center,
                zoom: 10,
                speed: 1.5,
            });

            searchInput.value = `${city.name}, ${city.admin1 || ''}, ${city.country}`;
            
            await handle_charts(city.lat, city.lon, city.name);
        }
    } catch (error) {
        console.error('Error handling location selection:', error);
    }
}

// Initialize search functionality
function initializeSearch() {
    // Handle city search
    const handleSearch = async (query) => {
        if (!query) {
            searchResults.style.display = 'none';
            return;
        }

        try {
            const response = await fetch(`/api/city?city=${encodeURIComponent(query)}`);
            const data = await response.json();

            if (data.body && data.body.results) {
                const cities = data.body.results.slice(0, 10);

                searchResults.innerHTML = cities.map(city => `
                    <div class="search-result"
                         data-lat="${city.lat}"
                         data-lon="${city.lon}"
                         data-name="${city.name}">
                        ${city.name}, ${city.admin1 || ''}, ${city.country}
                    </div>
                `).join('');

                searchResults.style.display = 'block';

                // Add click handlers to results
                document.querySelectorAll('.search-result').forEach(result => {
                    result.addEventListener('click', async () => {
                        const lat = parseFloat(result.dataset.lat);
                        const lon = parseFloat(result.dataset.lon);
                        const name = result.dataset.name;

                        await handleLocationSelect(lat, lon);
                        searchResults.style.display = 'none';
                    });
                });
            }
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    };

    // Add input event listener (triggers on every input)
    searchInput.addEventListener('input', (e) => {
        handleSearch(e.target.value);
    });

    // Close results when clicking outside
    document.addEventListener('click', (e) => {
        if (!searchInput.contains(e.target) && !searchResults.contains(e.target)) {
            searchResults.style.display = 'none';
        }
    });
}

mapboxMap.on('click', async (e) => {
    const { lat, lng: lon } = e.lngLat;
    await handleLocationSelect(lat, lon);
});

// Update the initialization code
document.addEventListener('DOMContentLoaded', () => {
    // Add click handlers for nav buttons
    const navButtons = document.querySelectorAll('.nav-button');
    navButtons.forEach(button => {
        button.addEventListener('click', () => {
            // Remove active class from all buttons
            navButtons.forEach(btn => btn.classList.remove('active'));
            // Add active class to clicked button
            button.classList.add('active');
            
            // Handle panel display based on data-panel attribute
            const panelType = button.dataset.panel;
            handlePanelDisplay(panelType);
        });
    });

    initializeSearch();
});
// import { Chart } from 'chart.js';

import matrix_chart_config from './matrix_chart.js';
import line_chart_config from './line_chart.js';
import { sand_config, siltyLoam_config, silt_config, clay_config } from './soil_charts.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-date-fns';
import 'chartjs-scale-timestack';
import zoomPlugin from 'chartjs-plugin-zoom';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';
import { BarController, BarElement, Chart, LinearScale, LineController, LineElement, PointElement, TimeSeriesScale, Title, Tooltip, Legend, Filler } from 'chart.js';
Chart.register(annotationPlugin, TimeSeriesScale, LinearScale, LineController, LineElement, PointElement, MatrixController, MatrixElement, BarElement, BarController, Tooltip, Title, Legend, zoomPlugin, Filler);
Chart.defaults.color = '#fff';

// Fetch and process wind data
async function loadWindData() {
    try {
        const response = await fetch('public/json/winddata.json');
        const data = await response.json();
        return {
            times: data.data_1h.time.map(t => new Date(t).valueOf()),
            windSpeeds: data.data_1h.windspeedprofile_150_mb,
            windDirections: data.data_1h.winddirectionprofile_150_mb
        };
    } catch (error) {
        console.error('Error loading wind data:', error);
        return null;
    }
}

async function loadSoilData() {
    try {
        const response = await fetch('public/json/soil.json');
        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error('Error loading soil data:', error);
        return null;
    }
}

async function loadCloudData() {
    try {
        const res = await fetch('public/json/clouds.json');
        const data = await res.json();

        const pressurelevels_clouds = data.metadata.pressurelevels_clouds;
        const times = data.data_1h.time;

        const cloudCover = data.data_1h.multimodelcloudprofile.map((item, index) => {
            return item.map((val, index2) => {
                return {
                    x: new Date(times[index2]).valueOf(),
                    y: pressurelevels_clouds[index],
                    value: val,
                }
            })
                .filter(item => item.value > 0)
        }).flat();
        return {
            dimensions: {
                x: data.data_1h.multimodelcloudprofile[0].length,
                y: data.metadata.pressurelevels_clouds.length
            },
            times: data.data_1h.time,
            cloudCover
        };
    } catch (error) {
        console.error('Error loading cloud data:', error);
        return null;
    }
}


function to_vector(x, y) {
    return x.map((val, index) => {
        return {
            x: new Date(val ).valueOf(),
            y: y[index]
        }
    })
}

function init_or_update_chart(chart_id, config) {
    const exists = Chart.getChart(chart_id);
    if (exists) {
        exists.update();
    } else {
        new Chart(document.getElementById(chart_id), config);
    }
}

export default async function initializeCharts() {
    const windData = await loadWindData();
    const soilData = await loadSoilData();
    const cloudData = await loadCloudData();
    if (windData && soilData && cloudData) {
        line_chart_config.data.datasets[0].data = to_vector(windData.times, windData.windSpeeds);
        
        // Store actual wind direction values for tooltips and arrow rotation
        line_chart_config.data.datasets[1].data = to_vector(windData.times, windData.windDirections);

        line_chart_config.data.datasets[2].data = to_vector(windData.times, soilData.data_1h.temperature);
        line_chart_config.data.datasets[3].data = to_vector(windData.times, soilData.data_1h.felttemperature);
        
        init_or_update_chart('lineChart', line_chart_config);
        
        // matrix_chart_config.data.labels = windData.times;
        matrix_chart_config.data.datasets[0].data = cloudData.cloudCover;
        matrix_chart_config.data.datasets[0].width = ({ chart }) => (chart.chartArea || {}).width / cloudData.dimensions.x - 1;
        matrix_chart_config.data.datasets[0].height = ({ chart }) => (chart.chartArea || {}).height / cloudData.dimensions.y - 1;

        matrix_chart_config.data.datasets[1].data = to_vector(windData.times, soilData.data_1h.precipitation);

        init_or_update_chart('heatmapChart', matrix_chart_config);

        sand_config.data.datasets[0].data = to_vector(soilData.soiltrafficability_1h.time, soilData.soiltrafficability_1h.sand);
        siltyLoam_config.data.datasets[0].data = to_vector(soilData.soiltrafficability_1h.time, soilData.soiltrafficability_1h.siltyloam);
        silt_config.data.datasets[0].data = to_vector(soilData.soiltrafficability_1h.time, soilData.soiltrafficability_1h.silt);
        clay_config.data.datasets[0].data = to_vector(soilData.soiltrafficability_1h.time, soilData.soiltrafficability_1h.clay);

        init_or_update_chart('sandChart', sand_config);

        init_or_update_chart('siltyLoamChart', siltyLoam_config);

        init_or_update_chart('siltChart', silt_config);

        init_or_update_chart('clayChart', clay_config);
    }
}

initializeCharts();
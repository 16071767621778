const sand_config = {
    type: 'line',
    data: {
        labels: [],
        datasets: [
            {
                label: 'Sand (%)',
                data: [],
                borderColor: 'rgb(255, 255, 255)',
                tension: 0.4,
                pointRadius: 0,
                indexAxis: 'x'
            },
        ],
    },
    options: {
        responsive: true,
        animation: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        scales: {
            x: {
                type: 'timestack',
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
            }
        },
        plugins: {
            title: {
                display: true,
                text: 'Sand'
            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'x',
                },
                limits: {
                    x: {
                        min: 'original',
                        max: 'original'
                    }
                }
            },
            annotation: {
                annotations: {
                    highBox: {
                        type: 'box',
                        yMin: 0.53,
                        yMax: 1,
                        backgroundColor: 'rgba(29, 178, 49, 1)',
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw'
                    },
                    lowBox: {
                        type: 'box',
                        yMin: 0,
                        yMax: 0.53,
                        backgroundColor: 'rgba(237, 79, 50, 1)',
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw'
                    }
                }
            },
            legend: {
                display: false,
            }
        },
    }
};

const siltyLoam_config = {
    type: 'line',
    data: {
        labels: [],
        datasets: [
            {
                label: 'Silty Loam (%)',
                data: [],
                borderColor: 'rgb(255, 255, 255)',
                tension: 0.4,
                pointRadius: 0,
                indexAxis: 'x'
            },
        ],
    },
    options: {
        responsive: true,
        animation: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        scales: {
            x: {
                type: 'timestack',
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
            }
        },
        plugins: {
            title: {
                display: true,
                text: 'Silty Loam'
            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'x',
                },
                limits: {
                    x: {
                        min: 'original',
                        max: 'original'
                    }
                }
            },
            annotation: {
                annotations: {
                    highBox: {
                        type: 'box',
                        yMin: 0.66,
                        yMax: 1,
                        backgroundColor: 'rgba(29, 178, 49, 1)',
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw'
                    },
                    mediumBox: {
                        type: 'box',
                        yMin: 0.33,
                        yMax: 0.66,
                        backgroundColor: 'rgba(255, 186, 0, 1)',
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw'
                    },
                    lowBox: {
                        type: 'box',
                        yMin: 0,
                        yMax: 0.33,
                        backgroundColor: 'rgba(237, 79, 50, 1)',
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw'
                    }
                }
            },
            legend: {
                display: false,
            }
        },
    }
};

const silt_config = {
    type: 'line',
    data: {
        labels: [],
        datasets: [
            {
                label: 'Silt (%)',
                data: [],
                borderColor: 'rgb(255, 255, 255)',
                tension: 0.4,
                pointRadius: 0,
                indexAxis: 'x'
            },
        ],
    },
    options: {
        responsive: true,
        animation: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        zoom: {
            zoom: {
                wheel: {
                    enabled: true,
                },
                pinch: {
                    enabled: true
                },
                mode: 'x',
            },
            limits: {
                x: {
                    min: 'original',
                    max: 'original'
                }
            }
        },
        stacked: false,
        scales: {
            x: {
                type: 'timestack',
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
            }
        },
        plugins: {
            title: {
                display: true,
                text: 'Silt'
            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'x',
                },
                limits: {
                    x: {
                        min: 'original',
                        max: 'original'
                    }
                }
            },
            annotation: {
                annotations: {
                    highBox: {
                        type: 'box',
                        yMin: 0.66,
                        yMax: 1,
                        backgroundColor: 'rgba(29, 178, 49, 1)',
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw'
                    },
                    mediumBox: {
                        type: 'box',
                        yMin: 0.33,
                        yMax: 0.66,
                        backgroundColor: 'rgba(255, 186, 0, 1)',
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw'
                    },
                    lowBox: {
                        type: 'box',
                        yMin: 0,
                        yMax: 0.33,
                        backgroundColor: 'rgba(237, 79, 50, 1)',
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw'
                    }
                }
            },
            legend: {
                display: false,
            }
        },
    }
};

const clay_config = {
    type: 'line',
    data: {
        labels: [],
        datasets: [
            {
                label: 'Clay (%)',
                data: [],
                borderColor: 'rgb(255, 255, 255)',
                tension: 0.4,
                pointRadius: 0,
                indexAxis: 'x'
            },
        ],
    },
    options: {
        responsive: true,
        animation: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        scales: {
            x: {
                type: 'timestack',
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
            }
        },
        plugins: {
            title: {
                display: true,
                text: 'Clay'
            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'x',
                },
                limits: {
                    x: {
                        min: 'original',
                        max: 'original'
                    }
                }
            },
            annotation: {
                annotations: {
                    highBox: {
                        type: 'box',
                        yMin: 0.66,
                        yMax: 1,
                        backgroundColor: 'rgba(237, 79, 50, 1)',
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw'
                    },
                    mediumBox: {
                        type: 'box',
                        yMin: 0.33,
                        yMax: 0.66,
                        backgroundColor: 'rgba(255, 186, 0, 1)',
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw'
                    },
                    lowBox: {
                        type: 'box',
                        yMin: 0,
                        yMax: 0.33,
                        backgroundColor: 'rgba(29, 178, 49, 1)',
                        borderWidth: 0,
                        drawTime: 'beforeDatasetsDraw'
                    }
                }
            },
            legend: {
                display: false,
            }
        },
    }
};

export { sand_config, siltyLoam_config, silt_config, clay_config };
mapboxgl.setRTLTextPlugin(
    'https://static.meteoblue.com/cdn/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
    null,
    true
);

const center = [50.171,66.352];
const mapboxMap = new mapboxgl.Map({
    center: center,
    container: 'maps',
    hash: 'coords',
    zoom: Number('5'),
    keyboard: false
});

mapboxMap.dragRotate.disable();
mapboxMap.touchZoomRotate.disableRotation();
mapboxMap.touchPitch.disable();

const inventory = 'https://maps-api.meteoblue.com/v1/map/inventory/filter?maps=windAnimation,gust,satellite,cloudsAndPrecipitation,temperature,sunshine,extremeForecastIndex'
    + '&enableOpenlayersLicensing=true'
    + `&lang=uk`            + '&temperatureUnit=C'
    + '&velocityUnit=km%2Fh'
    + '&lengthUnit=metric'
    + '&internal=true'
    + '&onlyUnrestricted=true';

// current location marker
var el = document.createElement('div');
el.className = 'current-location-marker';
new mapboxgl.Marker(el)
    .setLngLat(center)
    .addTo(mapboxMap);

const customMenuEntries = [
    { label: '©', action: 'attribution' },
    { label: 'Скріншот', action: 'screenshot' }
];

new meteoblueMapsPlugin({
    mapboxMap,
    inventory,
    apiKey: '1iw4Jq5NZK60Ig7O',
    hash: true,
    timezoneOffsetInSeconds: 7200,
    timezoneAbbreviation: "CAT",
    language: "uk",
    customMenuEntries: customMenuEntries,
    showTimeRanges: false,
});

export { mapboxMap };
import ChartDataLabels from 'chartjs-plugin-datalabels';

const wind_direction = {
    id: 'weather_icon',
    beforeDatasetDraw(chart, args, options) {
        const dataset = chart.data.datasets[1]; // Wind direction dataset
        if (!dataset || dataset.data.length === 0) return;
        
        chart.getDatasetMeta(1).data.forEach(point => {
            point.y = chart.chartArea.bottom;
        });
    }
}

// Chart configuration
/**
 * @type {import('chart.js').ChartConfiguration<'line', number[], string>}
 */
const config = {
    type: 'line',
    data: {
        labels: [],
        datasets: [
            {
                label: 'Wind Speed (m/s)',
                data: [],
                borderColor: 'rgb(54, 162, 235)',
                yAxisID: 'y1',
                pointRadius: 0,
                fill: false,
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                // indexAxis: 'x'
            },
            {
                label: 'Wind Direction (deg)',
                data: [],
                borderColor: 'rgb(255, 255, 255, 0)',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                tension: 0,
                yAxisID: 'y',
                pointRadius: 12,
                // indexAxis: 'x',
                fill: false,
            },
            {
                label: 'Temperature (C)',
                data: [],
                borderColor: 'rgb(255, 99, 132)',
                yAxisID: 'y1',
                pointRadius: 0,
                fill: false,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                indexAxis: 'x',
            },
            {
                label: 'Felt Temperature (C)',
                data: [],
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.4,
                yAxisID: 'y1',
                pointRadius: 0,
                fill: false,
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                // indexAxis: 'x'
            },
        ],
    },
    plugins: [ChartDataLabels, wind_direction],
    options: {
        responsive: true,
        animation: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        scales: {
            x: {
                type: 'timestack',
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
            }
        },
        plugins: {
            title: {
                display: true,
                text: 'Meteogram'
            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'x'
                },
                limits: {
                    x: {
                        min: 'original',
                        max: 'original'
                    }
                }
            },
            // decimation: {
            //     enabled: true,
            //     algorithm: 'lttb',
            //     samples: 50
            // },
            datalabels: {
                backgroundColor: 'rgb(0,0,0,0)',
                borderRadius: 50,
                color: 'white',
                font: {
                    size: 24,
                    lineHeight: 1
                },
                formatter: function (value, context) {
                    if (context.datasetIndex === 1 && context.dataIndex % 6 === 0) {
                        return '\u27a3';
                    } else {
                        return '';
                    }
                },
                rotation: function (ctx) {
                    if (ctx.datasetIndex === 1) {
                        return config.data.datasets[1].data[ctx.dataIndex].y;
                    }
                    return 0;
                },
                padding: 0
            },
            // tooltip: {
            //     callbacks: {
            //         label: function (context) {
            //             if (context.datasetIndex === 1) {
            //                 const actualValue = config.data.datasets[1]._data[context.dataIndex];
            //                 return `Wind Direction: ${actualValue}°`;
            //             }
            //             return `${context.dataset.label}: ${context.formattedValue}`;
            //         }
            //     }
            // }
        },
    }
};

export default config;
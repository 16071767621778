const config = {
    type: 'line',
    data: {
        labels: [],
        datasets: [
            {
                type: 'matrix',
                label: 'Cloud Cover',
                key: 'value',
                data: [],
                borderWidth: 0,
                backgroundColor: function (context) {
                    const value = context.dataset.data[context.dataIndex].value;
                    // Create a gradient from transparent to blue based on value
                    return `rgba(255, 255, 255, ${value / 100})`;
                },
                width: ({ chart }) => (chart.chartArea || {}).width / 2 - 1,
                height: ({ chart }) => (chart.chartArea || {}).height / 2 - 1,
                // width: 5,
                // height: 5
            },
            {
                type: 'bar',
                data: [],
                label: 'Precipitation',
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                    'rgba(255, 205, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(201, 203, 207, 0.2)'
                ],
                borderColor: [
                    'rgb(255, 99, 132)',
                    'rgb(255, 159, 64)',
                    'rgb(255, 205, 86)',
                    'rgb(75, 192, 192)',
                    'rgb(54, 162, 235)',
                    'rgb(153, 102, 255)',
                    'rgb(201, 203, 207)'
                ],
                borderWidth: 1,
                xAxisID: 'x',
                yAxisID: 't'
            }
        ],
    },
    options: {
        responsive: true,
        animation: false,
        // interaction: {
        //     mode: 'index',
        //     intersect: false,
        // },
        scales: {
            x: {
                type: 'timestack',
            },
            y: {
                position: 'left',
                reverse: false
            },
            t: {
                position: 'right',
            }
        },
        stacked: false,
        plugins: {
            title: {
                display: true,
                text: 'Cloud cover'
            },
            datalabels: {
                backgroundColor: 'white',
                borderRadius: 50,
                color: 'blue',
                font: {
                    size: 0
                },
                formatter: function (value) {
                    return '\u27a3'
                },
                rotation: function (ctx) {
                    return ctx.dataset.data[ctx.dataIndex] * 25;
                },
                padding: 0
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        if (context.dataset.label === 'Cloud Cover') {
                            const value = context.dataset.data[context.dataIndex].value;
                            return `Cloud Cover: ${value.toFixed(1)}%`;
                        }
                    }
                }
            },
        },
    }
};

export default config;